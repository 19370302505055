<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>C端数据</a-breadcrumb-item>
        <a-breadcrumb-item><a @click="onBack">租赁订单</a></a-breadcrumb-item>
        <a-breadcrumb-item>查看详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">订单信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单号：{{ info.orderSn }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单状态：{{ info.orderStatusValue }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              订单类型：{{ info.schemeModeValue }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8" v-if="info.schemeMode === 'rent'">
          <div class="card-item">
            <div class="card-item-title-content">
              租用方式：{{ info.modeValue }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8" v-if="info.schemeMode === 'rent'">
          <div class="card-item">
            <div class="card-item-title-content">
              租用时长：{{ info.modeNum }}{{ trans(info.mode) }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8" v-if="info.schemeMode === 'lease_purchase'">
          <div class="card-item">
            <div class="card-item-title-content">
              分期时长：{{ info.modeNum }}{{ trans(info.mode) }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              押金：{{ info.depositAmount }}元
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              {{
                `${info.schemeMode === "lease_purchase" ? "分" : "租"}期时间`
              }}：{{ tsFormat(info.leaseStartTime) }} 至
              {{ tsFormat(info.leaseEndTime) }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              计费方式：
              <a @click="countMethodsVisible = true">查看</a>
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              下单时间：{{ tsFormat(info.leaseStartTime) }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
      </a-row>
    </div>

    <!--用户信息-->
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">用户信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              用户姓名：
              <a @click="toUserData">{{ info.customerName }}</a>
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">手机号：{{ info.mobile }}</div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              身份证号：{{ info.idCardNo || "--" }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              用户协议：
              <a
                v-if="info.contractFile"
                target="_blank"
                :href="imageBaseUrl + info.contractFile.fileUrl"
              >
                {{ info.contractFile.fileName }}
              </a>
              <span v-else>--</span>
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
      </a-row>
    </div>

    <!--资产信息-->
    <div class="card-container" style="padding-bottom: 25px">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">资产信息</div>
          </div>
        </a-col>
      </a-row>
      <a-table
        :data-source="financeInfo"
        :columns="columns"
        :pagination="false"
      >
        <template slot="goodsSn" slot-scope="text, record">
          <a @click="toFicance(record)">{{ record.goodsSn }}</a>
        </template>
      </a-table>
    </div>

    <!--运营商信息-->
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">运营商信息</div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              机构名称：{{ info.corporationName || "--" }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              站点名称：{{ info.siteName }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              站点地点：
              <a @click="showMap = true" v-if="info.address">
                {{ info.areaName }} {{ info.address }}
              </a>
              <span v-else>--</span>
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title-content">
              站长姓名：{{ info.siteAdminName }}
            </div>
            <div class="card-item-content"></div>
          </div>
        </a-col>
      </a-row>
    </div>

    <!--账单信息-->
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">账单信息</div>
          </div>
        </a-col>
      </a-row>
      <!-- 总账单-->
      <a-table
        bordered
        class="table"
        :columns="billColumns"
        :data-source="totalBillList"
        :pagination="false"
        rowKey="(record, n) => n"
      >
        <template slot="title">
          总账单
          <span v-if="exemptAmount">(已豁免用户{{ exemptAmount }}元)</span>
        </template>
      </a-table>
      <!-- 各个账单-->
      <div v-for="(item, index) in billList" :key="index">
        <a-table
          bordered
          class="table"
          :columns="billColumns"
          :data-source="item.data"
          :pagination="false"
          rowKey="(record, n) => n"
        >
          <template slot="title">
            <div>
              账单{{ toChinesNum(index + 1) }}
              <span>(创建时间: {{ tsFormat(item.time) }})</span>
            </div>
          </template>
        </a-table>
      </div>
    </div>

    <!--用户付款信息-->
    <div class="card-container">
      <a-row>
        <a-col :span="8">
          <div class="card-item">
            <div class="card-item-title">用户付款信息</div>
          </div>
        </a-col>
      </a-row>
      <a-table
        class="table"
        :columns="payColumns"
        :data-source="paymentBillList"
        :pagination="false"
        rowKey=""
      >
        <template slot="paymentId" slot-scope="text, record">
          <a @click="openFinanceModal(record)">{{ record.paymentId }}</a>
        </template>
        <template slot="paymentTime" slot-scope="text, record">
          <div>
            {{ record.paymentTime ? tsFormat(record.paymentTime) : "--" }}
          </div>
        </template>
      </a-table>
    </div>

    <!--  站点当前位置  -->
    <a-modal
      title="站点当前位置"
      :footer="null"
      :visible="showMap"
      @cancel="showMap = false"
    >
      <CurrentLocation
        :latitude="latitude"
        :longitude="longitude"
        :position="position"
      />
    </a-modal>

    <!--  计费方式  -->
    <a-modal
      width="400px"
      title="计费方式"
      :footer="null"
      :visible="countMethodsVisible"
      @cancel="countMethodsVisible = false"
    >
      <!--分期购车-->
      <div class="methods-card" v-if="info.schemeMode === 'lease_purchase'">
        <div class="flexStyle">
          <div>首付款</div>
          <div>{{ rule.downPayment }}元</div>
        </div>
        <div class="flexStyle">
          <div>服务费</div>
          <div>{{ rule.serviceCharge }}元/期</div>
        </div>
        <div class="flexStyle">
          <div>期数设置</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="true" disabled>12期</a-radio>
          <div>每期共还款{{ rule.rentPerPeriod }}元</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.prepaymentPenaltyFlag" disabled>
            提前还款违约金
          </a-radio>
        </div>
        <div class="flexStyle littleGroup">
          <div>已还期数小于等于设定期数</div>
          <div>{{ rule.prepaymentPenaltyLessSetPeriod }}期</div>
        </div>
        <div class="flexStyle littleGroup">
          <div>收取违约金</div>
          <div>{{ rule.prepaymentPenaltyFixedAmount }}元</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.refundPenaltyFlag" disabled>
            未到期退车违约金
          </a-radio>
        </div>
        <div class="flexStyle littleGroup">
          <div>已还期数小于等于设定期数</div>
          <div>{{ rule.refundRepaidLessSetPeriod }}期</div>
        </div>
        <div class="flexStyle littleGroup">
          <div>收取违约金</div>
          <div>{{ rule.fixedAmount }}元</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.lateFeesCollectFlag" disabled>
            逾期滞纳金
          </a-radio>
        </div>
        <div class="flexStyle littleGroup">
          <div>每天收取</div>
          <div>{{ rule.lateFeesCollect }}元</div>
        </div>
      </div>
      <!--按 小时/天 计费-->
      <div
        class="methods-card"
        v-if="info.schemeMode === 'rent' && rule.mode !== 'by_month'"
      >
        <div class="flexStyle">
          <div>
            每{{
              rule.mode === "by_day"
                ? "天"
                : rule.mode === "by_hour"
                ? "小时"
                : "分钟"
            }}计费
          </div>
          <div>{{ rule.hourlyDailyBilling }}元</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.minimumLengthFlag" disabled>
            最小时长限定
          </a-radio>
          <div>
            {{ rule.minimumLengthLimit
            }}{{
              rule.mode === "by_day"
                ? "天"
                : rule.mode === "by_hour"
                ? "小时"
                : "分钟"
            }}
          </div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.maximumDurationFlag" disabled>
            最大时长限定
          </a-radio>
          <div>
            {{ rule.maximumDurationLimit
            }}{{
              rule.mode === "by_day"
                ? "天"
                : rule.mode === "by_hour"
                ? "小时"
                : "分钟"
            }}
          </div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.leaseExemptionFlag" disabled>
            起租豁免时长
          </a-radio>
          <div>{{ rule.leaseExemptionDuration }}分钟</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.vehicleChangeExemptionFlag" disabled>
            还车豁免时长
          </a-radio>
          <div>{{ rule.vehicleChangeExemptionDuration }}分钟</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.exceedingLeaseDurationFlag" disabled>
            超出租用时长还车
          </a-radio>
        </div>
        <div class="flexStyle littleGroup">
          <div>计费间隔</div>
          <div>
            {{ rule.billingInterval
            }}{{ rule.mode === "by_day" ? "小时" : "分钟" }}
          </div>
        </div>
        <div class="flexStyle littleGroup">
          <div>收费标准</div>
          <div>{{ rule.chargingStandards }}元</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.billingLimitFlag" disabled>
            {{ `每${rule.mode === "by_month" ? "" : "天"}计费限制` }}
          </a-radio>
          <div>{{ rule.billingLimit }}元</div>
        </div>
      </div>
      <!--按 月 计费-->
      <div
        class="methods-card"
        v-if="info.schemeMode === 'rent' && rule.mode === 'by_month'"
      >
        <div class="flexStyle">
          <div>期数设置</div>
        </div>
        <!--遍历期数设置-->
        <div class="flexStyle">
          <a-checkbox :checked="rule.periodsNumber" disabled>1个月</a-checkbox>
          <div>每月租金{{ rule.rentPerPeriod }}元</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.leaseExemptionFlag" disabled>
            起租豁免时长
          </a-radio>
          <div>{{ rule.leaseExemptionDuration }}分钟</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.vehicleChangeExemptionFlag" disabled>
            还车豁免时长
          </a-radio>
          <div>{{ rule.vehicleChangeExemptionDuration }}分钟</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.refundPenaltyFlag" disabled>
            未到期退车违约金
          </a-radio>
        </div>
        <div class="flexStyle littleGroup">
          <div>已还期数小于等于设定期数</div>
          <div>{{ rule.refundRepaidLessSetPeriod }}期</div>
        </div>
        <div class="flexStyle littleGroup">
          <div>收取违约金</div>
          <div>{{ rule.fixedAmount }}元</div>
        </div>
        <div class="flexStyle">
          <a-radio :checked="rule.lateFeesCollectFlag" disabled>
            逾期滞纳金每天收取
          </a-radio>
          <div>{{ rule.lateFeesCollect }}元</div>
        </div>
      </div>
    </a-modal>

    <!--  费用构成说明  -->
    <a-modal
      title="费用构成说明"
      :footer="null"
      :visible="financeVisible"
      @cancel="financeVisible = false"
    >
      <div style="margin-bottom: 20px">
        流水号: {{ FinanceModalInfo.paymentId }}
      </div>
      <a-table
        class="commodity__table"
        :columns="financeColumns"
        :data-source="billTableData"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="billTypeValue" slot-scope="text, record">
          <div>
            {{ record.actualPayment > 0 ? "收取" : "退还"
            }}{{ record.billTypeValue }}
          </div>
        </template>
        <template slot="actualPayment" slot-scope="text, record">
          <div>
            {{
              record.actualPayment > 0
                ? record.actualPayment
                : -record.actualPayment
            }}
          </div>
        </template>
        <template slot="footer">
          <div>合计(元): {{ totalMount.toFixed(2) }}</div>
        </template>
      </a-table>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat, toChinesNum } from "@/components/DateUtils";
import { imageBaseUrl } from "@/services/HttpService";
import CurrentLocation from "@/components/CurrentLocation";
import {
  getBillDetailByPaymentId,
  getBillInfoDetail,
  getDXSJLeaseOrderDetail,
  getPaymentInfo
} from "@/services/LeaseManage";

const columns = [
  {
    title: "商品编号",
    dataIndex: "skuCode"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "20%"
  },
  {
    title: "序列号",
    dataIndex: "goodsSn",
    scopedSlots: { customRender: "goodsSn" }
  },
  {
    title: "资产类型",
    dataIndex: "leaseAssetTypeValue"
  },
  {
    title: "运营状态",
    dataIndex: "operationStatusValue"
  }
];
const payColumns = [
  {
    title: "流水号",
    dataIndex: "paymentId",
    width: "",
    scopedSlots: { customRender: "paymentId" }
  },
  {
    title: "付款方",
    dataIndex: "payer",
    width: ""
  },
  {
    title: "收款方",
    dataIndex: "payee",
    width: ""
  },
  {
    title: "金额（元）",
    dataIndex: "amount",
    width: ""
  },
  {
    title: "支付时间",
    dataIndex: "paymentTime",
    width: "",
    scopedSlots: { customRender: "paymentTime" }
  },
  {
    title: "支付方式",
    dataIndex: "payTypeValue",
    width: ""
  },
  {
    title: "支付通道",
    dataIndex: "payModeValue",
    width: ""
  },
  {
    title: "支付状态",
    dataIndex: "payStatusValue",
    width: ""
  }
];
const billColumns = [
  {
    title: "费用项",
    dataIndex: "billTypeValue",
    width: ""
  },
  {
    title: "应付(元)",
    dataIndex: "paymentAmount",
    width: ""
  },
  {
    title: "已付(元)",
    dataIndex: "actualPayment",
    width: ""
  }
];
const financeColumns = [
  {
    title: "项目",
    dataIndex: "billTypeValue",
    scopedSlots: { customRender: "billTypeValue" }
  },
  {
    title: "金额(元)",
    dataIndex: "actualPayment",
    scopedSlots: { customRender: "actualPayment" }
  }
];
export default {
  components: { CurrentLocation },
  data() {
    return {
      tsFormat,
      dtFormat,
      toChinesNum,
      imageBaseUrl,
      financeColumns,
      id: this.$route.params.id,
      info: {},
      rule: {
        mode: "by_month"
      }, // 计费规则
      tblData: [],
      financeInfo: [], // 资产信息
      columns,
      payColumns,
      billColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      billLoading: false,
      showMap: false,
      latitude: "",
      longitude: "",
      position: "",
      financeVisible: false, // 费用构成说明弹窗
      billTableData: [],
      FinanceModalInfo: {},
      countMethodsVisible: false, // 计费方式弹窗
      totalMount: 0,
      totalBillList: [],
      billList: [], // 各个账单列表
      paymentBillList: [], // 用户付款信息数据
      exemptAmount: 0 // 豁免金额
    };
  },
  mounted() {
    this.onLoadDetail();
    this.getBillInfoDetail();
    this.getPaymentInfo();
  },
  methods: {
    //返回上一级
    onBack() {
      this.$router.go(-1);
    },
    //获取详情信息
    async onLoadDetail() {
      const { data } = await getDXSJLeaseOrderDetail(this.id);
      if (data.code === "SUCCESS") {
        this.info = data.data;
        this.rule = data.data.accountingRuleSnapshot || {};
        this.longitude = data.data.longitude;
        this.latitude = data.data.latitude;
        this.position = data.data.address || "";
        // 资产信息表格
        this.financeInfo = [
          {
            skuCode: data.data.skuCode,
            skuName: data.data.skuName,
            goodsSn: data.data.goodsSn,
            leaseAssetTypeValue: data.data.leaseAssetTypeValue,
            operationStatusValue: data.data.operationStatusValue,
            leaseAssetType: data.data.leaseAssetType
          }
        ];
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 跳转用户详情页
    toUserData() {
      let routeData = this.$router.resolve({
        name: "webConsumerDetail",
        query: { id: this.info.customerId }
      });
      window.open(routeData.href, "_blank");
    },
    // 跳转资产详情页
    toFicance(record) {
      let routeName = "";
      if (record.leaseAssetType === "vehicle") {
        routeName = "CarDetail";
      } else {
        routeName = "BatteryDetail";
      }
      this.$router.push({
        name: routeName,
        query: { id: this.info.leaseAssetId }
      });
    },
    // 打开费用说明弹窗
    async openFinanceModal(record) {
      this.FinanceModalInfo = record;
      const { data } = await getBillDetailByPaymentId(record.paymentId);
      if (data.code === "SUCCESS") {
        this.billTableData = data.data;
        this.totalMount = data.data.reduce((prev, cur) => {
          return prev + cur.actualPayment;
        }, 0);
        this.financeVisible = true;
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    trans(val) {
      switch (val) {
        case "by_month":
          return "个月";
        case "by_day":
          return "天";
        case "by_hour":
          return "小时";
        case "by_minute":
          return "分钟";
        default:
          break;
      }
    },
    // 获取账单信息
    async getBillInfoDetail() {
      const { data } = await getBillInfoDetail(this.id);
      if (data.code === "SUCCESS") {
        this.exemptAmount = data.data.exemptAmount || 0;
        const obj = data.data.billMap || {};
        for (let i in obj) {
          this.billList.push({
            time: i,
            data: obj[i]
          });
        }
        this.billList.sort((a, b) => {
          return new Date(a.time).getTime() - new Date(b.time).getTime();
        });
        //   处理总计表格
        const bill = data.data;
        const arr = [];
        arr.push({
          billTypeValue: "押金",
          paymentAmount: bill.paymentAntecedentAmount || 0,
          actualPayment: bill.actuallyAntecedentAmount || 0
        });
        arr.push({
          billTypeValue: "租金",
          paymentAmount: bill.paymentCharterAmount || 0,
          actualPayment: bill.actuallyCharterAmount || 0
        });
        arr.push({
          billTypeValue: "违约金",
          paymentAmount: bill.paymentLiquidatedAmount || 0,
          actualPayment: bill.actuallyLiquidatedAmount || 0
        });
        arr.push({
          billTypeValue: "滞纳金",
          paymentAmount: bill.paymentOverdueAmount || 0,
          actualPayment: bill.actuallyOverdueAmount || 0
        });
        arr.push({
          billTypeValue: "服务费",
          paymentAmount: bill.paymentServiceChargeAmount || 0,
          actualPayment: bill.actuallyServiceChargeAmount || 0
        });
        arr.push({
          billTypeValue: "管理费",
          paymentAmount: bill.paymentOverheadChargeAmount || 0,
          actualPayment: bill.actuallyOverheadChargeAmount || 0
        });
        arr.push({
          billTypeValue: "调度费",
          paymentAmount: bill.paymentDispatchAmount || 0,
          actualPayment: bill.actuallyDispatchAmount || 0
        });
        this.totalBillList = arr.filter(
          item => item.paymentAmount || item.actualPayment
        );
        this.totalBillList.push({
          billTypeValue: "总计",
          paymentAmount: bill.paymentTotalAmount || 0,
          actualPayment: bill.actuallyTotalAmount || 0
        });
      } else {
        this.$message.error(data.errorMsg);
      }
    },
    // 获取用户付款信息
    async getPaymentInfo() {
      const { data } = await getPaymentInfo(this.id);
      console.log(data);
      if (data.code === "SUCCESS") {
        this.paymentBillList = data.data;
      } else {
        this.$message.error(data.errorMsg);
      }
    }
  }
};
</script>

<style scoped>
/deep/ .ant-table.ant-table-bordered .ant-table-title {
  text-align: center;
  background-color: rgba(55, 105, 255, 0.1);
  color: rgba(0, 0, 0, 0.85);
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}

/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}

.info-container /deep/ .ant-form-item {
  margin: 0;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

.card-container {
  background-color: white;
  margin: 20px;
  padding: 25px 25px 5px 25px;
}

.card-item {
  display: flex;
  margin-bottom: 20px;
}

.card-item-title {
  font-weight: 600;
  margin-right: 10px;
}

.card-item-title-content {
  margin-right: 10px;
  min-width: 70px;
}

.table {
  margin-bottom: 20px;
}
.flexStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 30px;
}
.methods-card {
  border: 2px solid #cccccc;
  border-top: none;
  padding: 15px;
}
.littleGroup {
  padding-left: 24px;
  color: rgba(00, 00, 00, 0.45);
}
</style>
