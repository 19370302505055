<template>
  <div id="map"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

window._AMapSecurityConfig = {
  securityJsCode: "8bd908ead940215404427b8f61e8e493"
};
export default {
  name: "CurrentLocation",
  props: {
    longitude: {
      type: String
    },
    latitude: {
      type: String
    },
    position: {
      type: String
    }
  },
  data() {
    return {
      canterMarker: [this.longitude, this.latitude]
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // 信息窗体的内容
      const content = [`<div style="width:10rem;">${this.position}</div>`];
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle",
          "AMap.Icon"
        ] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        const map = new AMap.Map("map", {
          // 设置地图容器id
          enableHighAccuracy: true,
          subdomains: [],
          content: content,
          viewMode: "2D", // 是否为3D地图模式
          zoom: 16, // 初始化地图级别
          center: this.canterMarker // 初始化地图中心点位置
        });
        let infoWindow = new AMap.InfoWindow({
          content: content.join("") //使用默认信息窗体框样式，显示信息内容
        });
        infoWindow.open(map, map.getCenter());
        const icon = new AMap.Icon({
          sizeL: new AMap.Size(30, 30),
          image: "/icon_marker.svg",
          imageSize: new AMap.Size(30, 30)
        });
        const marker = new AMap.Marker({
          position: new AMap.LngLat(this.longitude, this.latitude),
          offset: new AMap.Pixel(-15, -5),
          icon: icon // 添加 Icon 图标 URL
        });
        map.add(marker);
        marker.on("click", clickHandler);
        const _this = this;

        function clickHandler() {
          var position = new AMap.LngLat(_this.longitude, _this.latitude);
          infoWindow.open(map, position);
        }
      });
    }
  }
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 300px;
}
</style>
